import React from 'react';
import '../styles/text.less';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';

const About = () => (
  <Layout>
    <div>
      <div className="textcontent meta">
        <FormattedHTMLMessage id="about.text" />
        {/* <h1 className="colorHighlight">
            {intl.formatMessage({ id: 'about.headline' })}
          </h1>
          <h2>
            {intl.formatMessage({ id: 'about.block-1.headline' })}
          </h2>
          <div className="copy">
            {intl.formatMessage({ id: 'about.block-1.copy' })}
          </div>

          <h2>
            {intl.formatMessage({ id: 'about.block-2.headline' })}
          </h2>
          <div className="copy">
            {intl.formatMessage({ id: 'about.block-2.copy' })}
          </div>

          <h2>
            {intl.formatMessage({ id: 'about.block-3.headline' })}
          </h2>
          <div className="copy">
            {intl.formatMessage({ id: 'about.block-3.copy' })}
          </div> */}
      </div>
    </div>
  </Layout>
);

export default About;
